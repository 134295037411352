import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'

export default defineNuxtPlugin({
  // Set ssr to true to enable server-side rendering
  ssr: true,
  setup (nuxtApp) {
    const {
      ctfSpaceId,
      ctfEnvironment,
      ctfCdaAccessToken,
      ctfPreviewAccessToken
    } = useEnv()

    const httpLink = createHttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${ctfSpaceId}/environments/${ctfEnvironment}`,
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${ctfCdaAccessToken}`
      }
    })

    const httpLinkPreview = createHttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${ctfSpaceId}/environments/${ctfEnvironment}`,
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${ctfPreviewAccessToken}`
      }
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          // eslint-disable-next-line no-console
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          // Throw custom error or handle specific errors here
        })
      }
      if (networkError) {
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${networkError}`)
      }
    })

    const link = ApolloLink.from([errorLink, httpLink])
    const linkPreview = ApolloLink.from([errorLink, httpLinkPreview])

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache()
    })

    const clientPreview = new ApolloClient({
      linkPreview,
      cache: new InMemoryCache()
    })

    nuxtApp.provide('apolloClient', client)
    nuxtApp.provide('apolloClientPreview', clientPreview)
  }
})
