export const DEFAULT_ENABLE_HOTJAR_LIST = [
  '/health-insurance/benefits/',
  '/health-insurance/8-signs/',
  '/health-insurance/companies/',
  '/health-insurance/switch/',
  '/health-insurance/seniors/',
  '/home-loans/buying-duplex-first-home/',
  '/life-insurance/affordable-cover-over-fifty/',
  '/life-insurance/over-50s-review/',
  '/health-insurance/price-of-loyalty-v2/',
  '/health-insurance/dental-cover/',
  '/home-loans/rba-mortgage-switch/',
  '/home-loans/9-years-off-home-loan/',
  '/health-insurance/ambulance-cover/',
  '/health-insurance/singles/',
  '/health-insurance/quote/form/step1/',
  '/health-insurance/quote/form/step2/',
  '/health-insurance/quote/form/step3/',
  '/health-insurance/quote/form/details/',
  '/health-insurance/quotes-results/',
  '/health-insurance/quote/customer/',
  '/health-insurance/quote/resume/',
  '/health-insurance/visitors-visa-ovhc/',
  '/health-insurance/visitors-visa-ovhc/step2/',
  '/health-insurance/visitors-visa-ovhc/details/',
  '/health-insurance/visitors-visa-ovhc/confirmation/',
  '/life-insurance/quote/form/step1/',
  '/life-insurance/quote/form/step2/',
  '/life-insurance/quote/form/details/',
  '/health-insurance/visitors-visa-ovhc/quotes-results/',
  '/health-insurance/visitors-visa-ovhc/application/step1/',
  '/health-insurance/visitors-visa-ovhc/application/step2/',
  '/health-insurance/visitors-visa-ovhc/application/step3/',
  '/health-insurance/visitors-visa-ovhc/application/step4/',
  '/health-insurance/visitors-visa-ovhc/application/step5/',
  '/home-loans/quote/form/step1/',
  '/home-loans/quote/form/step2/',
  '/home-loans/quote/form/step3/',
  '/home-loans/quote/form/step4/',
  '/home-loans/quote/form/step5/',
  '/home-loans/quote/form/step6/',
  '/home-loans/quote/form/details/'
]
