const getters = {
  /*
   * Accessor for quotes List
   * @param state
   * @returns {*}
   */
  getQuotes: state => {
    return state.quotes
  },

  /*
   * All quotes count
   * @param state
   * @returns {*}
   */
  quotesCount: state => {
    return state.quotes.length
  },
  /*
   * Filtered quotes count
   * @param state
   * @returns {function(*): *}
   */
  quotesFilteredCount: state => verticalSlugArray => {
    return state.quotes.filter(quote => {
      return verticalSlugArray.includes(quote.vertical)
    }).length
  }
}
export default getters
