import gql from 'graphql-tag'

/*
 * Generating perks query
 * @param categoryList
 * @param limit
 * @param preview
 * @returns {string}
 */
export const generateVaultCallbackContentQuery = vertical => {
  return gql`
  query {
  compareMyProductCollection(where: {vertical_contains: "${vertical}"}) {
    items{
      title
      header
      primaryCta
      secondaryCta
      disclaimer
      disclaimerDynamic
      description
    }
  }
}
`
}
