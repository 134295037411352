/*
 | ======================================================
 | This File is for member hub vault API services handling
 | ======================================================
 */
import { ApiService } from './api.service'

class VaultService {
  constructor () {
    this.request = new ApiService()
  }

  /*
     * Vault save API service
     * @param formData
     * @returns {*}
     */
  save = formData => {
    return this.request.post('/vaults', formData)
  }

  /*
     * Vault List API service
     * @returns {*}
     */
  list = () => {
    return this.request.get('/vaults')
  }

  /*
     * Vault product delete API service
     * @param productId
     * @returns {*}
     */
  delete = productId => {
    return this.request.delete(`/vaults/${productId}`)
  }

  /*
     * vault product details API service
     * @param productId
     * @returns {*}
     */
  show = productId => {
    return this.request.get(`/vaults/${productId}`, { canCancel: true })
  }

  /*
     * vault product update API service
     * @param productId
     * @param formData
     * @returns {*}
     */
  update = (productId, formData) => {
    return this.request.patch(`/vaults/${productId}`, formData)
  }

  /*
     * Vault product rebate update API service
     * @param productId
     * @param formData
     * @returns {*}
     */
  rebate = (productId, formData) => {
    return this.request.patch(`/vault-rebate/${productId}`, formData)
  }

  /*
   * Policies dropdown for vault API service
   * @param url
   * @returns {*}
   */
  policies = url => {
    return this.request.get(url)
  }

  /*
   * Amply policy details API service
   * @param policyProductId
   * @returns {*}
   */
  policyDetails = policyProductId => {
    return this.request.get(`/data/health-insurance/policies/${policyProductId}`)
  }

  /*
   * Validate purchased user API service
   * @param email
   * @returns {*}
   */
  validateUser = email => {
    return this.request.get('/validate-purchased-user', { params: { email } })
  }

  /*
   * Purchased user signup API service
   * @param formData
   * @returns {*}
   */
  purchasedUserSignup = formData => {
    return this.request.post('/purchased-user-signup', formData)
  }

  /*
   * Purchase Sync API service
   * @returns {*}
   */
  purchaseSync = () => {
    return this.request.get('/vault-sync')
  }

  /*
   * Create lead API service
   * @param vaultId
   * @param params
   * @returns {*}
   */
  createLead = (vaultId, params = {}) => {
    return this.request.get(`/vault-lead-id/${vaultId}`, { params })
  }

  /*
   * Save file(s) API service
   * @param vaultId
   * @param files
   * @returns {*}
   */
  saveFiles = (vaultId, files) => {
    return this.request.post(`/vaults/${vaultId}/files`, files)
  }

  /*
   * Rename file API service
   * @param vaultId
   * @param fileId
   * @param formData
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | null, FetchError | null>}
   */
  renameFile = (vaultId, fileId, formData) => {
    return this.request.patch(`/vaults/${vaultId}/files/${fileId}/rename`, formData)
  }

  /*
   * Delete file API service
   * @param vaultId
   * @param fileId
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | null, FetchError | null>}
   */
  deleteFile = (vaultId, fileId) => {
    return this.request.delete(`/vaults/${vaultId}/files/${fileId}`)
  }

  /*
   * Archive vault API service
   * @param vaultId
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | null, FetchError | null>}
   */
  archive = vaultId => {
    return this.request.post(`/vaults/${vaultId}/archive`)
  }

  /*
   * Restore archived vault API service
   * @param vaultId
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | null, FetchError | null>}
   */
  restore = vaultId => {
    return this.request.post(`/vaults/${vaultId}/restore`)
  }

  /*
   * Archive vault List API service
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | null, FetchError | null>}
   */
  archiveList = () => {
    return this.request.get('/vaults/archives')
  }

  /*
   * Vault LIC product details API service
   * @param apiUrl
   * @param opportunityId
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | null, FetchError | null>}
   */
  licDetails = (apiUrl, opportunityId) => {
    const { apikeyLifeInsurance } = useRuntimeConfig().public
    return this.request.get(apiUrl, { params: { opportunity_id: opportunityId, apikey: apikeyLifeInsurance } })
  }

  /*
   * Vault Health policies list API service (New Version)
   * @param payload
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | DefaultAsyncDataValue, DefaultAsyncDataErrorValue | FetchError>}
   */
  healthInsurancePolicies (payload) {
    return this.request.post('/data/health-insurance/policies-v2' , payload)
  }

  /*
   * Vault Health policies duplicate check API service
   * @param payload
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | DefaultAsyncDataValue, DefaultAsyncDataErrorValue | FetchError>}
   */
  duplicateHealthInsurancePolicies (payload) {
    return this.request.post('/data/health-insurance/policies-v2-duplicate-check' , payload)
  }
  vendors = params => {
    return this.request.get('/data/vendors', { params })
  }
  /*
   * Vault Rate rise confirm API service
   * @param vaultId
   * @param formData
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | DefaultAsyncDataValue, DefaultAsyncDataErrorValue | FetchError>}
   */
  rateRiseConfirm = (vaultId, formData) => {
    return this.request.post(`/vaults/${vaultId}/rate-rise-confirm`, formData)
  }
  /*
 * Matches the address using the provided payload.
 *
 * @param {Object} payload - The payload containing address data to match.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
  addressMatch = payload => {
    return this.request.post('/vault-address-match', payload, { canCancel: true })
  }

  /*
 * Updates the lead ID for a specific vault.
 *
 * @param {string} vaultId - The ID of the vault to update.
 * @param {string} uuid - The UUID to update the lead ID with.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
  updateLeadId = (vaultId, uuid) => {
    return this.request.patch(`/vault-lead-id/${vaultId}/${uuid}`)
  }
}

export default VaultService
