const state = () => ({
  userProfile: getLocal('auth_user')
    ? JSON.parse(getLocal('auth_user'))
    : {},
  token: getLocal('token')
    ? JSON.parse(getLocal('token'))
    : {},
  signUpData: null,
  authModuleParams: {},
  onBoardingFormData: {},
  defaultAuthState: `auth_state_${Date.now()}`,
  tmpProfile: {},// temp profile data store for onboarding
  psSignUp: false
})

export default state
