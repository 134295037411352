import { envKeysPrivate, envKeysPublic } from '~/config/env.config'

export default function generateEnvs () {
  const config = useRuntimeConfig()

  const privateKeys = Object.fromEntries(
    Object.entries(envKeysPrivate).map(([key]) => [key, config[key]])
  )

  /* const publicKeys = Object.fromEntries(
    Object.entries(envKeysPublic).map(([key]) => [key, config.public[key]])
  ) */

  const publicKeys = {}
  for (const [key, value] of Object.entries(envKeysPublic)) {
    // Check if config.public[key] is available, if not, use the value from envKeysPublic
    publicKeys[key] = config.public[key] || value
  }

  return {
    ...privateKeys,
    ...publicKeys
  }
}
