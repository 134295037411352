/**
 * example:
 * $bus.on('test', payload => {})
 * $bus.emit('test', 'value')
 */

import mitt from 'mitt'

export default defineNuxtPlugin(nuxtApp => {
  const bus = mitt()

  nuxtApp.provide('bus', bus)
})
