<template>
  <NuxtLayout
    id="app-main"
    class="bg-gray-350 h-full"
  >
    <section class="py-28 h-full relative text-center overflow-hidden flex items-center">
      <div class="mx-auto pb-24">
        <p class="mb-4 text-6.5xl font-extrabold">
          Oh no :(
        </p>

        <p class="text-lg font-bold">
          We can’t seem to find the page you’re looking for
        </p>

        <nuxt-img
          width="495"
          height="200"
          class="mt-12 mx-auto max-w-full"
          src="https://asset.compareclub.com.au/images/404-illustration.svg"
          alt="404 illustration"
        />

        <atoms-cta
          :full="false"
          class="mt-12 relative z-10 mx-auto"
          @click="handleError"
        >
          BACK TO HOMEPAGE
        </atoms-cta>
      </div>

      <div class="absolute w-full bottom-0 left-0 -z-0">
        <svg
          width="1440"
          height="260"
          viewBox="0 0 1440 260"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-auto"
        >
          <g
            style="mix-blend-mode: multiply"
            opacity="0.2"
          >
            <path
              d="M1440 260H0V117.53C306 -32.5422 1072 -45.6735 1440 117.53V260Z"
              fill="url(#paint0_linear_9551:53897)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_9551:53897"
              x1="720"
              y1="85.3075"
              x2="720"
              y2="155.466"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D2D2D2" />
              <stop
                offset="1"
                stop-color="white"
                stop-opacity="0"
              />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  </NuxtLayout>
</template>

<script setup>
defineOptions({
  name: 'ErrorPage'
})

const props = defineProps({
  error: {
    type: Object,
    default: () => ({})
  }
})

// eslint-disable-next-line no-console
console.error({
  ...props.error
})

const handleError = () => clearError({ redirect: '/' })
</script>
