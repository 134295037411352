import { default as _91slug_93yGIGOy8o9JMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as index0breBabrgWMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesrcJBb5kveHMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93GwFl3TH6NQMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexrf1YxpSQ1wMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackBSCzwov4A1Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usvQl5tkzlmjMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackoBdCCVuIrSMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergeR541TRqpqwMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as comprehensiveqseU69PpqeMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexIvWlu7UxrbMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93RAS0GGP6vYMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsu1vzisjOXXMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultstkpqJZ5Ep3Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as index6IMutf3r7NMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexAbQRH8vCryMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticeSbsDCUJidlMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsTXgmgPicVPMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usLxgajTwyN5Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93tDH4wxnOiwMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexLY5t3PmLmzMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93kDbEAVVUDRMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93p7116KJzUOMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsT5O7RlNCRBMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexGx9x90bXCfMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as claim_45offer7su9QY728iMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as indexUuASso2O0eMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexuFUgagXllvMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexodPsIbSOTYMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexCMJRIa043zMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailsJciw95d4fGMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue?macro=true";
import { default as completionT2mRYyOr2PMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue?macro=true";
import { default as detailsNe6LZarhO3Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93vVhrxlHvKIMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93yICiLgEtsBMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue?macro=true";
import { default as _91step_935XIJqgLT9XMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93d2tW628CVSMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93KM9hHaJsjRMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailskXmjgUJmvVMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebatew86T58onxBMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as digital_45eicyquFbqMfV4Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as error1W0QuvA71sMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45youVTWTmaappWMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as indexOfZ4mqpxZgMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93688bQR29dIMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsEw9dnfxSKoMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youlqh4xIpLsjMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93C9nrHKzfzDMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexSe4wHjgUVyMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93TNy5bukeHhMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexHyF5LpSICWMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45password9CMuWkxVHNMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93OoXUAXDb7AMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expired0vx40qvhkdMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexS0Gr3lgIMgMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexVuW8MevIWCMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errorn0Pnx3HPNDMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexJrc5s5xDH9Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bwXbAFLPviJMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatsnaLAw63u6Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indextdT1YupsbHMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addajjCPoqEl2Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupEpweHHdlzxMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45sale6bOVyUNWrCMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexeO7Ntva6uTMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93AJ0vXaqd72Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsRAi4Kd2hMJMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0zx9ijlkXemMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as index2LWR3rtIC7Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youfv7nsfipeHMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexTkVqdkLzuPMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as index3KsQo5toF0Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as index0fdEEzcOGwMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexhwqRE8eEQvMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93phOkQaQlovMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93H17LTyJzD1Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationW6p6amTshRMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsWv5NlngaVYMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexmXnFkKcmODMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsNDDwdPRxBIMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youxTLnCgqIVwMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_938f5P5osWeYMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexRqIY4buvTJMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexT0gZgac0FdMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45rateso5cpeg4VyBMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93NsoGkcCVAdMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsCtSm7MNiIOMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youyjftMuDgrwMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexs2Hywtd14cMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/index.vue?macro=true";
import { default as confirmationJH5QmgldMqMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as details98H5mJo6G1Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as index5rQZE4IZJWMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexQNgO0anHGGMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addafxVOQDDtlMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youaukq2uzyIlMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as index06I7efcj0yMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successXfs1qkxi9oMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_936fDPJUTwtzMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsvHuCBEBdTWMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as loginf02ZSWNSugMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93DlTZzgVsqpMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93pEm2CEJVfLMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexSgbGkrtxUrMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsztxfSHxcuDMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexnpobnEnb8dMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91step_93pAQciwFzLdMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as detailsLg66xKEHkUMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as thank_45you1GeTmw5lmQMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as post_45sale_45thank_45youcqzcB2ZYH4Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93SDkW7D5CsrMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93aKXcKo0EIqMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_932K6pY6KaY7Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policy93tAfQKhm2Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerwugQdC6MPsMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordyybfhvZZ0lMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useSvOPww4bUVMeta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyZtNshdOeY5Meta } from "/codebuild/output/src3238155741/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbackBSCzwov4A1Meta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93tDH4wxnOiwMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsT5O7RlNCRBMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexGx9x90bXCfMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offer7su9QY728iMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexUuASso2O0eMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexuFUgagXllvMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexodPsIbSOTYMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexCMJRIa043zMeta?.props ?? {"verificationNeeded":true},
    meta: indexCMJRIa043zMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product?/:vertical()/additional-details",
    meta: additional_45detailsJciw95d4fGMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product?/:vertical()/completion",
    meta: completionT2mRYyOr2PMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: detailsNe6LZarhO3Meta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product?/form/energy/step/:step()",
    meta: _91step_93vVhrxlHvKIMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product?/form/generic/step/:step()",
    meta: _91step_93yICiLgEtsBMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product?/form/health-insurance/step/:step()",
    meta: _91step_935XIJqgLT9XMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product?/form/home-loan/step/:step()",
    meta: _91step_93d2tW628CVSMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product?/form/life-insurance/step/:step()",
    meta: _91step_93KM9hHaJsjRMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailskXmjgUJmvVMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebatew86T58onxBMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password9CMuWkxVHNMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginf02ZSWNSugMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerwugQdC6MPsMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordyybfhvZZ0lMeta || {},
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src3238155741/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]