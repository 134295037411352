import _pick from 'underscore/cjs/pick.js'
import _identity from 'underscore/cjs/identity.js'

const getters = {
  GET_UTMS_AS_QUERY: state => {
    const filteredUtms = _pick(state.utms, _identity)

    const utmsToQueryParams = Object.keys(filteredUtms)
      .map(key => key + '=' + filteredUtms[key])
      .join('&')

    return utmsToQueryParams
  },

  GET_UTMS_PARTNER: state => partner => {
    const partnersField = state.partners_field[partner]
    const utms = state.utms

    const queryParams = Object.keys(state.utms)
      .map(key => {
        if (utms[key] !== '') {
          let partnerField = ''
          if (partnersField[key]) {
            partnerField = `&${partnersField[key]}=${utms[key]}`
          }

          return encodeURI(`${key}=${utms[key]}${partnerField}`)
        }
        return ''
      })
      .filter(param => param !== '')
      .join('&')

    return queryParams
  },

  GET_UTMS_LANDING_PAGE: state => {
    return getSession('LANDING_PAGE') ||
      _pick(state.utms, 'landing_page') ||
      window.location.origin
  }
}

export default getters
