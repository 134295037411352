import * as vt from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import './index.scss'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(vt.default, {
    timeout: 4000,
    hideProgressBar: true,
    draggablePercent: 0.6,
    pauseOnHover: true,
    position: vt.POSITION.BOTTOM_LEFT,
    transition: 'Vue-Toastification__fade',
    closeButtonClassName: 'close-toast-icon',
    toastDefaults: {
      [vt.TYPE.ERROR]: {
        icon: {
          iconClass: 'circle-error-icon'
        }
      },
      [vt.TYPE.SUCCESS]: {
        icon: {
          iconClass: 'circle-check-icon'
        }
      }
    }
  })

  return {
    provide: {
      toast: vt.useToast()
    }
  }
})
