import {
  generateVaultCallbackContentQuery
} from './schema/vault.schema'

class VaultCallbackContentService {
  constructor () {
    const { $apolloClient } = useNuxtApp()
    this.client = $apolloClient
    this.data = {}
  }

  getVaultContent = vertical => {
    return this.client.query({
      query: generateVaultCallbackContentQuery(vertical),
      variables: {}
    }).then(response => {
      return response?.data?.compareMyProductCollection?.items || []
    }).catch(err => {
      return new Error(err)
    })
  }
}

export default VaultCallbackContentService
