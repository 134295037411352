<!--
  ! Make sure if new chevron svg is added
  ! default direction is facing down
  ! so we don't need to map out the rotationMap
-->

<template>
  <svg
    v-if="type === 'default' && size === 'sm'"
    :class="rotate"
    width="15"
    height="10"
    viewBox="0 0 15 10"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.49083 8.80625L13 3.29709L11.7048 2L7.49083 6.21667L3.27783 2L1.98163 3.29617L7.49083 8.80625Z" />
  </svg>

  <svg
    v-else-if="type === 'default' && size === 'xs'"
    :class="rotate"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.757324 1.24432L4.99982 5.48682L9.24232 1.24432L8.53532 0.536817L4.99982 4.07282L1.46432 0.536817L0.757324 1.24432Z" fill="#193560"/>
  </svg>

  <svg
    v-else-if="type === 'default' && size === 'default'"
    :class="rotate"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 15.713L18.01 9.70302L16.597 8.28802L12 12.888L7.40399 8.28802L5.98999 9.70202L12 15.713Z" />
  </svg>

  <svg
    v-else-if="type === 'default' && size === 'md'"
    :class="rotate"
    width="18"
    height="10"
    viewBox="0 0 18 10"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.514893 1.4652L8.99989 9.9502L17.4849 1.4652L16.0709 0.0501976L8.99989 7.1222L1.92889 0.0501976L0.514893 1.4652Z" />
  </svg>

  <svg
    v-else-if="type === 'default' && size === 'lg'"
    :class="rotate"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.515 8.46549L12 16.9505L20.485 8.46549L19.071 7.05049L12 14.1225L4.929 7.05049L3.515 8.46549Z" />
  </svg>

  <svg
    v-else-if="type === 'default' && size === 'xl'"
    :class="rotate"
    width="35"
    height="20"
    viewBox="0 0 35 20"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.121764 2.93L17.0918 19.9L34.0618 2.93L31.2338 0.099999L17.0918 14.244L2.94977 0.1L0.121764 2.93Z" />
  </svg>

  <svg
    v-else-if="type === 'thin'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    :class="rotate"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.6563 5.64338L7.99956 11.3L2.3429 5.64338L3.28556 4.70005L7.99956 9.41472L12.7136 4.70005L13.6563 5.64338Z" />
  </svg>

  <svg
    v-else-if="type === 'solid'"
    :class="rotate"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" />
  </svg>

  <svg
    v-else-if="type === 'rounded'"
    :class="rotate"
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2L7 7L2 2"
      :stroke="fill"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="type === 'circle' && size !== 'xl'"
    :class="rotate"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C17.995 5.58378 14.4162 2.00496 10 2ZM9.95 13.5L5 8.55L6.414 7.136L9.95 10.671L13.486 7.136L14.9 8.55L9.95 13.5Z" />
  </svg>

  <svg
    v-else-if="type === 'circle' && size === 'xl'"
    :class="rotate"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="#193560"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.665357 14.0013C0.673457 6.64085 6.63826 0.676043 13.9987 0.667961L14.2654 0.667961C21.5926 0.814536 27.4281 6.84741 27.3308 14.1755C27.2336 21.5037 21.2402 27.3796 13.9116 27.3317C6.58296 27.2838 0.666857 21.3301 0.665357 14.0013ZM24.6653 14.0013C24.6653 8.11025 19.8897 3.33462 13.9987 3.33462C8.10766 3.33462 3.33206 8.11025 3.33206 14.0013C3.33206 19.8924 8.10766 24.668 13.9987 24.668C19.887 24.6614 24.6587 19.8896 24.6653 14.0013ZM7.33206 12.068L9.21866 10.1827L13.9321 14.896L18.6453 10.1827L20.532 12.068L13.9321 18.668L7.33206 12.0693L7.33206 12.068Z"
      fill="#193560"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconsChevron',

  props: {
    direction: {
      type: String,
      default: 'right',
      validator: value => ['left', 'right', 'top', 'bottom'].includes(value)
    },

    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'solid', 'thin', 'rounded', 'circle'].includes(value)
    },

    size: {
      type: String,
      default: 'sm',
      validator: value => ['default', 'xs', 'sm', 'md', 'lg', 'xl'].includes(value)
    },

    fill: {
      type: String,
      default: '#193560'
    }
  },

  computed: {
    rotate () {
      const direction = this.direction

      /**
       * if new svg is aadded make sure the default direction
       * of the svg is facing down
       */
      const rotationMap = {
        bottom: 'rotate-0',
        top: 'rotate-180',
        right: '-rotate-90',
        left: 'rotate-90'
      }

      return `${rotationMap[direction]} origin-center`
    }
  }
}
</script>
