const state = () => ({
  utms: {
    utm_source: '',
    utm_medium: '',
    utm_term: '',
    utm_content: '',
    utm_campaign: '',
    utm_provider: '',
    utm_name: '',
    website_source: 'CompareClub',
    fbclid: '',
    irclickid: '',
    IRClickID: '',
    gclid: '',
    wbraid: '',
    gbraid: '',
    msclkid: '',
    ob_click_id: '',
    'click-id': '',
    f_cid: '',
    conversion_name: '',
    ga_client_id: '',
    optimizely_visitor_id: '',
    optimizely_active_experiment_ids: '',
    optimizely_variation_map: ''
  },

  optimizely_utms: {
    optimizely_visitor_id: [],
    optimizely_active_experiment_ids: [],
    optimizely_variation_map: {}
  },

  partners_field: {
    monevo: {
      utm_medium: 'v1',
      utm_source: 'v2',
      utm_content: 'v3',
      utm_term: 'v4',
      utm_campaign: 'v5',
      landing_page: 'ref'
    }
  }
})

export default state
