import _isEmpty from 'underscore/cjs/isEmpty.js'
import TrustpilotService from '@/server/service/trustpilot'

// Declare trustpilotService instance here
const trustpilotService = new TrustpilotService()

export const useTrustpilotStore = defineStore('trustpilot', {
  state: () => ({
    trustpilotData: null,
    trustpilotReviews: null,
    trustpilotSingleReview: undefined,
    isLoadingTrustpilotReviews: false,
    trustpilotDataHasError: false,
    trustpilotReviewsHasError: false
  }),

  actions: {
    // Todo: Remove this function as the data is fetched in the App.vue
    async fetchTrustpilotData () {
      const { data } = await useFetch('/api/trustpilot/data')
      this.trustpilotData = data
    },

    async fetchReviews (params) {
      if (!this.trustpilotReviews) {
        // console.log('Trustpilot:fetchReviews():Data Exists!...')
        // return; // Reviews already fetched
      }
      this.isLoadingTrustpilotReviews = true
      try {
        // const trustpilotService = new TrustpilotService()
        const data = await trustpilotService.fetchReviews(params)
        const reviews = data.reviews.map(review => {
          return {
            title: review.title,
            text: review.text,
            id: review.id,
            customer: review.consumer.displayName,
            customer_id: review.consumer.id,
            createdAt: review.createdAt,
            stars: `https://images-static.trustpilot.com/api/stars/${review.stars}/star.svg`,
            showReadmore: false,
            collapse: false
          }
        })
        this.trustpilotReviews = reviews
        this.trustpilotReviewsHasError = false
      } catch {
        // Handle error
        this.trustpilotReviewsHasError = true
      }
      this.isLoadingTrustpilotReviews = false
    },

    async fetchReviewById (params) {
      // const trustpilotService = new TrustpilotService()
      try {
        const data = await trustpilotService.fetchReviewById(params)
        if (_isEmpty(data) || data.id !== params.id) {
          return
        }
        this.trustpilotSingleReview = {
          title: data.title,
          text: data.text,
          id: data.id,
          customer: data.consumer.displayName,
          customer_id: data.consumer.id,
          createdAt: data.createdAt,
          stars: `https://images-static.trustpilot.com/api/stars/${data.stars}/star.svg`,
          showReadmore: false,
          collapse: false
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }
  },
  getters: {
    latestReview () {
      if (_isEmpty(this.trustpilotReviews)) {
        return []
      }
      const list = JSON.parse(JSON.stringify(this.trustpilotReviews))
      // Return the first review
      return list.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })[0]
    }
  }
})
