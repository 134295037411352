/*
 | ======================================================
 | This File is for Uploading file(s) API services handling
 | ======================================================
 */

import { ApiService } from './api.service'

class UploadService {
  constructor () {
    this.request = new ApiService()
  }

  /*
   * New file upload API service
   * @param formData
   * Math random is added to avoid cache issue on parallel upload
   * @returns {*}
   */
  new = formData => {
    return this.request.post(`/file?t=${Math.random()}`, formData)
  }

  /*
   * File delete API service
   * @param fileId
   * @returns {*}
   */
  delete = fileId => {
    return this.request.delete(`/file/${fileId}`)
  }
}

export default UploadService
