import { CONFIG } from '~/config/trustpilot.config.ts'

const handleData = data => {
  if (Object.keys(data).length === 0) {
    throw {
      status: 400,
      error: 'Bad Request',
      message: 'Data is required!'
    }
  }
}

class TrustpilotService {
  constructor () {
    this.baseUrl = CONFIG.baseUrl
  }

  fetchTrustpilotInfo () {
    return $fetch('/v1/business-units/find', {
      method: 'GET',
      baseURL: `${this.baseUrl}`,
      params: CONFIG.params
      // canCancel: true
    })
  }

  fetchStarsData (data) {
    handleData(data)
    return $fetch(`${this.baseUrl}/v1/resources/strings/stars/${data.score.stars}`, {
      params: CONFIG.params
    })
  }

  fetchStarsImage (data) {
    handleData(data)
    return $fetch(`${this.baseUrl}/v1/resources/images/stars/${data.score.stars}`, {
      params: CONFIG.params
    })
  }

  fetchLocale (data) {
    handleData(data)
    return $fetch(`${this.baseUrl}/v1/business-units/${data.id}/web-links`, {
      params: {
        ...CONFIG.params,
        locale: `en-${data.country}`
      }
    })
  }

  fetchReviews (params) {
    return $fetch(`${this.baseUrl}/v1/business-units/${CONFIG.id}/reviews`, {
      params: {
        ...CONFIG.params,
        ...(params ?? {})
      }
      // canCancel: true
    })
  }

  fetchReviewById (params) {
    return $fetch(`${this.baseUrl}/v1/reviews/${params.id}`, {
      params: {
        ...CONFIG.params
      }
      // canCancel: true
    })
  }
}

export default TrustpilotService
