/**
 * Sets `LANDING_PAGE` value in session storage
 * it automatically clears when tab is closed.
 */
export default defineNuxtPlugin({
  name: 'landingPage',
  parallel: true,
  setup () {
    /**
     * get landing page per sessionStorage
     */
    if (import.meta.client) {
      const landingPageSession = getSession('LANDING_PAGE')

      if (!landingPageSession) {
        setSession('LANDING_PAGE', window.location.href)
      }
    }
  }
})
