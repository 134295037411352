import VaultService from '../../server/service/vault.service'
import UploadService from '../../server/service/upload.service'
import VaultCallbackContentService from '~/server/contentful/vault.service'
import memberhubConstants from '../../constants/memberhub.constants'
const vaultService = new VaultService()
const uploadService = new UploadService()

const actions = {
  loader (status = true) {
    const layoutStore = useLayoutsStore()
    layoutStore.setPageLoader(status)
  },
  /*
   * Health vault form store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async saveVaultForm (payload) {
    this.loader(true)
    const r = await vaultService.save(payload.formData)
    return checkResponse(r, true)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault products list API service
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async vaultProducts () {
    this.vaultLoader = true
    const r = await vaultService.list()
    return checkResponse(r, false)
      .then(response => {
        this.setProduct({})
        this.resetInlineEditParams({})
        this.setLifeProduct({})
        this.setVaultsProductsList(response.data || [])
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.vaultLoader = false
      })
  },
  /*
 * Vault archived products list API service
 * @param context
 * @returns {Promise<unknown>}
 */
  async vaultArchivedProducts () {
    this.loader(true)
    const r = await vaultService.archiveList()
    return checkResponse(r, false)
      .then(response => {
        this.setVaultsArchivedProductsList(response.data || [])
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault product details fetch store action
   * @param context
   * @param payload
   * @param showLoader
   * @returns {Promise<unknown>}
   */
  async show (payload, showLoader = true) {
    this.loader(showLoader)
    const r = await vaultService.show(payload)
    return checkResponse(r, false)
      .then(response => {
        this.setProduct(response.data)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault LIC product details fetch store action
   * @param apiUrl
   * @param payload
   * @returns {Promise<void>}
   */
  async showLicDetails (apiUrl, payload) {
    this.loader(true)
    const r = await vaultService.licDetails(apiUrl, payload)
    return checkResponse(r, false)
      .then(response => {
        this.setLifeProduct(response.data)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Fetch amply policy details with policy product id
   * @param payload
   * @param showLoader
   * @returns {Promise<unknown>}
   */
  async policy (payload, showLoader = true) {
    this.loader(showLoader)
    const r = await vaultService.policyDetails(payload)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault product details update store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async update (payload) {
    this.loader(true)
    const r = await vaultService.update(payload.productId, payload.formData)
    return checkResponse(r, true, payload.schema)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault product rebate update store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async updateRebate (payload) {
    this.loader(true)
    const r = await vaultService.rebate(payload.productId, payload.formData)
    return checkResponse(r, true, payload.schema)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Delete vault product store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async deleteProduct (payload) {
    this.loader(true)
    const r = await vaultService.delete(payload)
    return checkResponse(r, true)
      .then(response => {
        this.removeProductFromList(payload)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  async policies (payload) {
    const r = await vaultService.policies(payload)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  /*
   * Validate user for purchased product
   * @param payload
   * @returns {Promise<void>}
   */
  async checkPurchasedUser (payload) {
    this.loader(true)
    const r = await vaultService.validateUser(payload)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Save purchased user
   * @param payload
   * @returns {Promise<void>}
   */
  async savePurchasedUser (payload) {
    this.loader(true)
    const { $tracking } = useNuxtApp()
    const r = await vaultService.purchasedUserSignup(payload.formData)
    return checkResponse(r, true, payload.schema)
      .then(response => {
        $tracking.customEvent('My Account Sign up', { emailHashed: response.data?.hash_id })
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Fetching health policies for vault
   * @param payload
   * @returns {Promise<void>}
   */
  async fetchHealthPolicies (payload) {
    this.loader(true)
    const r = await vaultService.healthInsurancePolicies(payload)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  async fetchDuplicateHealthPolicies (payload) {
    this.loader(true)
    const r = await vaultService.duplicateHealthInsurancePolicies(payload)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Mutator for vault formData
   * @param state
   * @param payload
   */
  async setVaultFormData (payload) {
    await new Promise(resolve => {
      const formData = payload.formData || payload
      if (payload.model) {
        const arrayDiff = Object.keys(payload.model).filter(k => !Object.keys(payload.formData).includes(k))
        if (arrayDiff.length) {
          arrayDiff.forEach(item => {
            formData[item] = null
          })
        }
      }
      this.vaultFormData = { ...this.vaultFormData, ...formData }
      setTimeout(() => {
        resolve()
      })
    })
  },
  /*
   * Vault purchase API service
   * @param payload
   * @returns {Promise<T>}
   */
  async purchaseVaultSync () {
    this.isSyncing = true
    const r = await vaultService.purchaseSync()
    return checkResponse(r, false)
      .then(response => {
        if (response.data?.length) {
          this.setProduct({})
          this.setVaultsProductsList(response.data || [])
        }
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.isSyncing = false
      })
  },
  /*
   * Create lead from vault store action
   * @param payload
   * @returns {Promise<T>}
   */
  async createLeadFromVault (payload) {
    this.loader(true)
    const r = await vaultService.createLead(payload.vaultId, payload.params)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
  * Archive vault store action
  * @param vaultId
  * @returns {Promise<T>}
  */
  async vaultArchive (vaultId) {
    this.loader(true)
    const r = await vaultService.archive(vaultId)
    return checkResponse(r, true)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
  * Archive vault store action
  * @param vaultId
  * @returns {Promise<T>}
  */
  async vaultRestore (vaultId) {
    this.loader(true)
    const r = await vaultService.restore(vaultId)
    return checkResponse(r, true)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault file upload store action
   * @param formData
   * @returns {Promise<T>}
   */
  async vaultFileUpload (formData) {
    const r = await uploadService.new(formData)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  /*
   * Vault file save store action
   * @param payload
   * @returns {Promise<void>}
   */
  async vaultFileSave (payload) {
    this.loader(true)
    const r = await vaultService.saveFiles(payload.vaultId, { files: payload.files })
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault file rename store action
   * @param payload
   * @returns {Promise<void>}
   */
  async vaultFileRename (payload) {
    this.loader(true)
    const r = await vaultService.renameFile(payload.vaultId, payload.fileId, payload.formData)
    return checkResponse(r, true)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault file delete store action
   * @param payload
   * @returns {Promise<void>}
   */
  async vaultFileDelete (payload) {
    this.loader(true)
    const r = await vaultService.deleteFile(payload.vaultId, payload.fileId)
    return checkResponse(r, true)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * File delete store action
   * @param fileId
   * @returns {Promise<void>}
   */
  async fileDelete (fileId) {
    this.loader(true)
    const r = await uploadService.delete(fileId)
    return checkResponse(r, true)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  async fetchVendors (verticalId) {
    this.loader(true)
    const r = await vaultService.vendors({ vertical_id: verticalId })
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Vault rate rise confirm store action
   * @param payload
   * @returns {Promise<void>}
   */
  async confirmRateRise (payload) {
    this.loader(true)
    const r = await vaultService.rateRiseConfirm(payload.vaultId, payload.formData)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
 * Matches the address using the provided payload.
 *
 * @param {Object} payload - The payload containing address data to match.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
  async addressMatch (payload) {
    this.loader(true)
    const r = await vaultService.addressMatch(payload)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },

  /*
 * Updates the lead ID for a specific vault.
 *
 * @param {Object} params - The parameters containing vaultId and uuid.
 * @param {string} params.vaultId - The ID of the vault to update.
 * @param {string} params.uuid - The UUID to update the lead ID with.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
  async updateLeadId ({ vaultId, uuid }) {
    this.loader(true)
    const r = await vaultService.updateLeadId(vaultId, uuid)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        this.loader(false)
      })
  },
  /*
   * Mutator for vault formData
   * @param state
   */
  resetVaultFormData () {
    this.vaultFormData = {}
  },
  /*
   * Mutator for vault products list
   * @param state
   * @param payload
   */
  setVaultsProductsList (payload) {
    this.products = payload || []
  },
  /*
 * Mutator for vault products list
 * @param state
 * @param payload
 */
  setVaultsArchivedProductsList (payload) {
    this.archivedProducts = payload || []
  },
  /*
   * Mutator for vault products list reset
   * @param state
   */
  resetProductList () {
    this.products = []
  },
  /*
   * Resets the inline edit parameters to their initial state.
   * This method is used to clear any temporary data or states associated with inline editing features,
   * effectively resetting any changes made during the editing process.
   */
  resetInlineEditParams () {
    this.inlineEditParams = {}
  },
  /*
   * Resets the inline edit state.
   * This method is intended to reset the `isInlineEdit` flag to `false`, effectively
   * turning off inline editing mode. However, it incorrectly calls itself recursively,
   * leading to a stack overflow error. This method needs to be corrected to avoid
   * calling itself.
   */
  resetInlineEdit () {
    this.resetInlineEditParams()
    this.isInlineEdit = false
  },
  /*
   * Mutator for vault products list update
   * @param state
   * @param productId
   */
  removeProductFromList (productId) {
    const productIndex = this.products.indexOf(this.products.find(p => p.id === productId))
    if (productIndex >= 0) {
      this.products.splice(productIndex, 1)
    }
  },
  /*
   * Mutator for vault product details
   * @param state
   * @param payload
   */
  setProduct (payload) {
    this.product = payload
    if (payload?.payload) {
      if (payload?.payload?.policy_details && payload?.payload?.policy_details.product_type === 'Hospital' && payload?.payload?.cover_type === 'Combined') {
        this.product.payload.extra_cover_exists = true
      }

      const currentFormData = JSON.parse(JSON.stringify(payload?.payload))
      if(parseInt(currentFormData.vertical) === memberhubConstants.VERTICAL_ID.HEALTH_INSURANCE) {
        if (currentFormData && currentFormData.policy_details) {
          delete currentFormData.policy_details.hospital_covers
          delete currentFormData.policy_details.comparison_hospital_covers
          delete currentFormData.policy_details.comparison_extra_covers
          delete currentFormData.policy_details.extra_covers
          delete currentFormData.policy_details.extra_details
          delete currentFormData.policy_details.policy_features
        }
        if (currentFormData && currentFormData.extra_details) {
          delete currentFormData.extra_details.hospital_covers
          delete currentFormData.extra_details.comparison_hospital_covers
          delete currentFormData.extra_details.comparison_extra_covers
          delete currentFormData.extra_details.extra_covers
          delete currentFormData.extra_details.extra_details
          delete currentFormData.extra_details.policy_features
        }
        if (currentFormData?.income_tier_rebate) {
          delete currentFormData?.income_tier_rebate
        }
        if (currentFormData?.extra_cover_exists) {
          delete currentFormData?.extra_cover_exists
        }
        if(!currentFormData?.excess) {
          currentFormData.excess = currentFormData.policy_details?.excess || ''
        }
        if(!currentFormData.cover_type){
          if(!currentFormData.policy_details?.hospital_tier){
            currentFormData.cover_type = 'GeneralHealth'
          }else if(currentFormData.policy_details?.hospital_tier && currentFormData.policy_details?.extra_cover_level){
            currentFormData.cover_type = 'Combined'
          }else{
            currentFormData.cover_type = 'Hospital'
          }
        }
        if(!currentFormData?.hospital_cover_level) {
          currentFormData.hospital_cover_level = currentFormData.policy_details?.hospital_tier || ''
        }
      }
      this.vaultFormData = currentFormData
    } else {
      this.vaultFormData = {}
    }
  },
  /*
   * Fetch contentful vault callback content
   * @param vertical
   * @returns {Promise<*|{}>}
   */
  async fetchVaultCallbackContent (vertical = 'Health') {
    const service = new VaultCallbackContentService()
    const res = await service.getVaultContent(vertical)
    return res[0] || {}
  },
  /*
   * Set life product details
   * @param payload
   */
  setLifeProduct (payload) {
    this.lifeProduct = payload
  }
}
export default actions
