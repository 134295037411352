/*
 | =================================================
 | This File is for member hub vaults state handling
 | =================================================
 */

const state = {
  vaultFormData: {},
  products: [],
  product: {},
  lifeProduct: {},
  archivedProducts: [],
  vaultLoader: true,
  setManualHealthVaultActive: false,
  isSyncing: false,
  inlineEditParams: {},
  isInlineEdit: false,
  leadAddress: {}
}

export default () => {
  return state
}
