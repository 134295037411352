import { createInstance } from '@optimizely/optimizely-sdk'
import { DEFAULT_ENABLE_HOTJAR_LIST } from '~/constants/hotjar.constants'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface window {
    optimizely: any
  }
}

export default defineNuxtPlugin({
  name: 'optimizely',
  parallel: true,
  setup () {
    const snippetVersion = 6

    class Optimizely {
      optimizelyLoaded: boolean = false
      hotjarLoaded: boolean = false
      instance: any = null
      pageHasHotjar: boolean = false

      async init () {
        if (import.meta.client) {
          // Load Optimizely script
          await this.addOptimizelyScript()

          // Check if hotjar is enabled for the current page
          const isPageHasHotjar = await this.checkPageHasHotjar()

          if (isPageHasHotjar) {
            this.handleHotjarScript(true)
          }

          /*
            * Sets optimizely details, and generates optimizely data
            * if optimzely has already been initialized.
            *
            * This is trigged in app.vue route watch
           */
          if (this.optimizelyLoaded) {
            this.setOptimizelyDetails()
            await useUtmsStore().GENERATE_OPTIMIZELY_DATA()
          }
        }
      }

      checkPageHasHotjar () {
        this.pageHasHotjar = DEFAULT_ENABLE_HOTJAR_LIST.findIndex(url => window.location.pathname.includes(url)) !== -1
      
        return this.pageHasHotjar
      }

      addOptimizelyScript () {
        const { optimizelyScript } = useRuntimeConfig().public

        const optimizelyScriptEl = document.getElementById('optimizely-script')

        if (!optimizelyScriptEl) {
          useHead({
            script: [{
              src: `${optimizelyScript}`,
              type: 'text/javascript',
              id: 'optimizely-script',
              async: true,
              defer: true,
              onload: async () => {
                await this.createOptimizelyInstance()
                this.setOptimizelyDetails()
                await useUtmsStore().GENERATE_OPTIMIZELY_DATA()
              }
            }]
          })
        }
      }

      async createOptimizelyInstance () {
        const { optimizelySdkKey } = useRuntimeConfig().public

        this.instance = await createInstance({
          sdkKey: optimizelySdkKey,
          eventBatchSize: 100,
          eventFlushInterval: 1000,
          odpOptions: {
            disabled: true
          }
        })

        await this.instance.onReady()
          .then(() => {
            this.optimizelyLoaded = true

            // Check for hotjar feature enabled in optimizely
            // const profile = useUserStore().getUserProfile
            // const profileId = profile?.id ?? ''
            // const hotJarFeatureEnabled = this.instance.isFeatureEnabled('Hotjar', profileId.toString())

            // const enableHotjar = hotJarFeatureEnabled || this.pageHasHotjar
            // this.handleHotjarScript(enableHotjar)
          })
      }

      handleHotjarScript (enableHotjar) {
        const hotjarScriptEl = document.getElementById('hotjar-script')

        if (enableHotjar && !hotjarScriptEl) {
          const { hotjarId } = useRuntimeConfig().public

          useHead({
            script: [
              {
                src: `https://static.hotjar.com/c/hotjar-${hotjarId}.js?sv=${snippetVersion}`,
                type: 'text/javascript',
                id: 'hotjar-script',
                async: true,
                defer: true,
                onload: () => {
                  this.hotjarLoaded = true
                }
              }
            ]
          })
        } else if (!enableHotjar && hotjarScriptEl) {
          hotjarScriptEl?.remove()
        }
      }

      setOptimizelyDetails () {
        useUtmsStore().optimizely_utms = {
          optimizely_visitor_id: window.optimizely?.get('visitor_id'),
          optimizely_active_experiment_ids: window.optimizely?.get('state').getActiveExperimentIds(),
          optimizely_variation_map: window.optimizely?.get('state').getVariationMap()
        }
      }
    }

    const optimizelyClient = new Optimizely()

    return {
      provide: {
        optimizelyClient
      }
    }
  }
})
