import _isEmpty from 'underscore/cjs/isEmpty.js'
import memberhubConstants from '~/constants/memberhub.constants'
/*
 * API response analyser
 * @param response
 * @param showToast
 * @param schema
 * @returns {Promise<never>|Promise<unknown>}
 */

export function checkResponse (
  res,
  showToast = true,
  schema = {}
) {
  const { $toast, $bus } = useNuxtApp()
  // const userStore = useUserStore()
  const response = {
    code: res?.data?.value?.status || res?.data?.value?.code || res?.error?.value?.data?.code || res?.data?.value?.data?.status,
    data: res?.data?.value || res?.error?.value?.data || res?.data?.value?.data
  }

  if (_isEmpty(response)) {
    $toast.error('Something went wrong')

    return Promise.reject(response || {})
  }
  const code = response.code || response.status
  switch (parseInt(code)) {
    case memberhubConstants.API_SUCCESS:
    case memberhubConstants.API_CREATED:
      if (showToast) {
        $toast.success(response?.data?.message || response?.data?.data?.message || 'Successfully updated')
      }

      return Promise.resolve(response?.data || response)

    case memberhubConstants.API_VALIDATION_ERROR:
      if (response?.data?.errors) {
        Object.entries(response.data.errors).forEach(([key, value]) => {
          if (schema && !schema[key] && Array.isArray(schema) && !schema.find(item => item.name === key)) {
            if (showToast) $toast.error(value[0])
          } else {
            if(Array.isArray(schema)) {
              const currentSchema = schema.find(input => input.name === key)
              schema[schema.indexOf(currentSchema)] = {
                ...currentSchema,
                isSuccess: false,
                errorMessage: value[0]
              }
            }else if(schema.name === key){
              schema.isSuccess = false
              schema.errorMessage = value[0]
            }else{
              schema[key] = {
                ...schema[key],
                success: { status: false, message: '' },
                isSuccess: false,
                error: { status: true, message: value[0] }
              }
            }
            $bus.emit(`invalid-form-key-${key}`, { message: value[0] })
          }
        })

        return Promise.reject(response)
      }

      return Promise.reject(response)
    default:
      if (showToast) {
        $toast.error(response.data && response.data.message ? response.data.message : (response?.data?.data?.message || 'The Request Failed! Please try again.'))
      }

      return Promise.reject(response)
  }
}
