import { CONFIG } from '~/server/service/config'
export class ApiService {
  constructor (apiType = 'memberhub') {
    this.service = useFetch
    this.apiType = apiType
  }

  /*
     * Global api request service
     * @param method
     * @param url
     * @param data
     * @param config
     * @returns {*}
     */
  request = (method, url, data = {}, config = {}) => {
    const { environment } = useRuntimeConfig().public
    /*
     | ===============================================================================
     | Custom logic is applied for request url
     | If full URL is coming as request param, it will be used as request URL
     | otherwise, respective api-service's base url will be prepended with request URL
     | ===============================================================================
     */
    const searchCategories = ['http://', 'https://']
    const pattern = new RegExp(searchCategories.join('|'))
    const forceDirectUrlRequest = pattern.test(url)
    const baseURL = forceDirectUrlRequest ? url : CONFIG.baseUrl(environment, this.apiType)
    const options = {
      method,
      canCancel: config.canCancel,
      params: config.params,
      forceDirectUrlRequest,
      apiType: this.apiType,
      baseURL
    }
    if (method !== 'GET') {
      options.body = data
    }
    return this.service(url, options)
  }

  /*
     * GET API service request
     * @param url
     * @param config
     * @returns {*}
     */
  get = (url, config = {}) => {
    return this.request('GET', url, {}, config)
  }

  /*
     * POST API service request
     * @param url
     * @param data
     * @param config
     * @returns {*}
     */
  post = (url, data, config = {}) => {
    return this.request('POST', url, data, config)
  }

  /*
     * PUT API service request
     * @param url
     * @param data
     * @param config
     * @returns {*}
     */
  put = (url, data, config = {}) => {
    return this.request('PUT', url, data, config)
  }

  /*
     * PATCH API service request
     * @param url
     * @param data
     * @param config
     * @returns {*}
     */
  patch = (url, data, config = {}) => {
    return this.request('PATCH', url, data, config)
  }

  /*
     * DELETE API service request
     * @param url
     * @param config
     * @returns {*}
     */
  delete = (url, config = {}) => {
    return this.request('DELETE', url, {}, config)
  }
}
