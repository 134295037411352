const getters = {
  /*
   * Current user page or state for redirection
   * @param state
   * @returns {*}
   */
  getAuthUserSourcePage: state => {
    return state.authModuleParams.source_page_url
  },
  /*
   * Current user page or state query for redirection
   * @param state
   * @returns {*}
   */
  getAuthUserSourcePageQuery: state => {
    return state.authModuleParams.query
  },

  getUserProfile: state => {
    return state.userProfile
  },
  /*
   * Accessor for user logged in status
   * @param state
   * @returns {boolean}
   */
  isUserLoggedIn: state => {
    return !!Object.keys(state.token).length && !!Object.keys(state.userProfile).length
  },
  /*
   * Accessor for user onboarding form data
   * @param state
   * @returns {*}
   */
  getOnBoardingFormData: state => {
    return state.onBoardingFormData
  },
  /*
   * Email verified status
   * @returns {boolean}
   */
  isEmailVerified () {
    return !!this.getUserProfile.email_verified
  },

  /*
   * Email verified status
   * @returns {boolean}
   */
  isPurchasedSignUp: state => {
    return state.psSignUp
  }
}

export default getters
