import { documentToHtmlString as htmlToString } from '@contentful/rich-text-html-renderer'

import sha256 from 'crypto-js/sha256'
import parse from 'html-dom-parser'
import dayjs from 'dayjs'
import _isEmpty from 'underscore/cjs/isEmpty.js'
import _isObject from 'underscore/cjs/isObject.js'
import _isArray from 'underscore/cjs/isArray.js'
import duration from 'dayjs/plugin/duration.js'
dayjs.extend(duration)

// import EventBus from '../libs/event-bus/event-bus'

// export const authChecker = () => {
//   if (!window) {
//     return
//   }
//   return localStorage.getItem('token') !== null && localStorage.getItem('auth_user') !== null
// }

// const { $emit } = useNuxtApp()

// export const toastTests = message => {
//   $emit('toast:on', message)
// }

export const documentToHtmlString = doc => htmlToString(doc)

// /*
// generate pages that needs to be registered on the proxy
// */
// export const generateProxyLists = async (enableArticleandProfile = false) => {
//   const postsProxyUrl = process.env.POSTS_PROXY_URL
//   const postsLinks = {
//     '/health-insurance/companies/': postsProxyUrl,
//     '/health-insurance/rate-rise-calculator/': postsProxyUrl,
//     '/health-insurance/quick/': postsProxyUrl
//   }

//   if (enableArticleandProfile) {
//     await axios.get(process.env.POSTS_LIST_SOURCE)
//       .then(response => {
//         if (!response.data) {
//           return
//         }
//         response.data.articles.forEach(article => {
//           postsLinks[article] = postsProxyUrl
//         })
//         response.data.profiles.forEach(profile => {
//           postsLinks[profile] = postsProxyUrl
//         })
//       })
//   }
//   return postsLinks
// }

export const strToCapitalize = str => {
  try {
    if (!str) {
      return str
    }

    return str.replace(/\b\w/g, l => l.toUpperCase())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

/*
 * transforms string to Kebab Case
 * @param str {String}
 */
export const strToKebabCase = str => {
  if (!str) {
    return str
  }

  return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-')
}

/*
 * transforms string to Snake Case
 * @param str {String}
 */
export const strToSnakeCase = str => {
  if (!str) {
    return str
  }

  return str
    .replace(/\s/g, '_')
    .replace(/[^a-z|0-9]/gi, '_')
    .replace(/\B([A-Z])/g, '_$1')
    .toLowerCase()
    .replace(/__/, '_')
}
/*
 * transforms string to Camel Case
 * @param str {String}
 */
export const strToCamelCase = str => {
  if (!str) {
    return str
  }

  return `${str}`.toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, val) => val.toUpperCase())
}

export const strToPascalCase = str => {
  if (!str) {
    return str
  }

  return str.toLowerCase()
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3}`
    )
    .replace(new RegExp(/\w/), s => s.toUpperCase())
}

export const strToCapitalizeWords = str => {
  if (!str) {
    return str
  }

  return str.toLowerCase()
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      ($1, $2, $3) => ` ${$2.toUpperCase() + $3}`
    )
    .replace(new RegExp(/\w/), s => s.toUpperCase())
}

/*
 * Get an item in localstorage
 * @param key
 * @param val
 */
export const getLocal = key => {
  if (import.meta.client) {
    return localStorage.getItem(key)
  }
  return null
}

/*
 * Set an item in localstorage
 * @param key
 * @param val
 */
export const setLocal = (key, val) => {
  if (import.meta.client) {
    localStorage.setItem(key, val)
  }
}

/*
 * Remove an item from local storage
 * @param key
 */
export const removeLocal = key => {
  if (import.meta.client) {
    localStorage.removeItem(key)
  }
}

/*
 * Dispatching custom event to DOM
 * @param eventName
 * @param payload
 */
export const dispatchEvent = (eventName, payload) => {
  if (import.meta.client) {
    window.document.getElementById('app-main')?.dispatchEvent(new CustomEvent(eventName, { detail: payload }))
  }
}

/*
 * generates a unique id
 * @param str {String}
 */
export const generateUID = str => (`${(Math.random(Date.now() * Math.random(100 + 50))).toString(32)}${Math.random().toString(16)}${str ? `-${strToKebabCase(str)}` : ''}`).replace(/\./g, '')

/**
 * Returns the value of key in the map
 * if the key doesn't exist it returns "default" key value or undefined
 * @param key {String}
 * @param obj {Object}
 */
export const getKey = (key, obj) => {
  const tempKey = Object.keys(obj).find(item => {
    if (item.includes(',')) {
      const tempItem = (item.includes(',') && item.split(',')
        .map(e => e.trim())) || ''
      return tempItem.includes(key)
    }

    return item === key
  }) ?? key

  return (tempKey in obj ? obj[tempKey] : (obj?.default || ''))
}

/**
 * Returns the key where the value of the map matches the
 * param `value` passed.
 * If the none of the value matches it will return null
 *
 * @param value {String}
 * @param obj {Object}
 */
export const getKeyOfMap = (value = true, obj, _default = null) => {
  const values = Object.values(obj)
  const keys = Object.keys(obj)
  const findIndex = values.findIndex(item => item === value)

  return keys?.[findIndex] ?? _default
}

/*
 * scroll to id handler
 * @param id {String}
 * @param offset {Number}
 * @returns promise
 */
export const scrollToId = (element, offset = 0) => {
  const { top } = element.getBoundingClientRect()

  const html = import.meta.client && document.documentElement
  const windowOffsetTop = window.pageYOffset || html.scrollTop
  const targetPosition = top + windowOffsetTop - offset

  window.scrollTo({
    top: targetPosition,
    behavior: 'smooth'
  })

  return new Promise((resolve, reject) => {
    const failed = setTimeout(() => {
      reject(new Error('targetPosition not match with pageYOffset'))
    }, 2000)

    const scrollHandler = () => {
      if (self.pageYOffset === targetPosition) {
        window.removeEventListener('scroll', scrollHandler)
        clearTimeout(failed)
        resolve()
      }
    }

    if (self.pageYOffset === targetPosition) {
      clearTimeout(failed)
      resolve()
    } else {
      window.addEventListener('scroll', scrollHandler)
    }
  })
}

/*
 * check whether string contains html tag
 * @param str {String}
 * @returns {true|false}
 */
export const checkStringContainsHtml = str => {
  if (!str) {
    return str
  }

  return /<\/?[a-z][\s\S]*>/i.test(str)
}

/*
 * check whether elemt on viewport
 * @param element {HTML}
 * @returns Boolean
 */
export const isInViewport = element => {
  const SCROLL_TRIGGER = 80
  if (!element) {
    return false
  }
  const rect = element.getBoundingClientRect()

  return (
    rect.bottom - SCROLL_TRIGGER >= 0 &&
    rect.right >= 0 &&
    rect.top - SCROLL_TRIGGER <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * remove falsey items in object
 * @param obj {Object}
 */
export const removeFalseyObj = obj => {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const item = obj[key]

      if (!item || _isEmpty(item)) {
        delete obj[key]
      }
    }
  }

  return obj
}

/*
 * generate excerpt
 * @param str {String}
 * @param maxLength {Int}
 * @returns Boolean
 */
export const generateExcerpt = (str, maxLength = 120) => {
  return (str && str.substring(0, maxLength) + (str.length > maxLength ? '...' : '')) || str
}

/*
 * remove html from string
 * @param str {String}
 * @returns String
 */
export const removeHtmlString = str => {
  try {
    return (str && str.replace(/<(.|\n)*?>/g, '')
      .replace(/&amp;/g, '&')) || str
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(str, `=>  ${error}`)
    return str
  }
}

// /*
//  * generate news object from contentful data
//  * @param object {Object}
//  * @param extendObj {Object}
//  * @returns Object
//  */
// export const generateNewsObjectFromContentful = (object, extendObj) => {
//   return {
//     title: object.title,
//     tag: object.vertical,
//     content: object?.mainContent ? generateExcerpt(removeHtmlString(documentToHtmlString(object.mainContent.json))) : 'Content',
//     authorName: object?.author ? object.author.name : 'Compare Club',
//     authorUrl: object?.author ? object.author.authorUrl : '/',
//     url: object.slug,
//     image: object?.featureImage ? object.featureImage.url : FEATURED_IMAGE_DEFAULT_IMAGE,
//     ...extendObj
//   }
// }

// /**
//  * returns formated number
//  * @param obj {String}
//  */
// export const formatPhoneNumber = number => {
//   return number
//     ? '+61' + number.substring(1)
//     : ''
// }

/**
 * transform Object into query url
 * @param Obj {Object}
 * @returns String
 */
export const transformObjectToQueryUrl = Obj => {
  return Object.keys(Obj).map(key => key + '=' + Obj[key]).join('&')
}

/**
 * generates a SHA256 hash
 * @param text {String}
 * @returns String
 */
export const hash256 = text => {
  if (!text) {
    return
  }

  text = `${text}`.toLowerCase()
  return sha256(text).toString()
}

/**
 * generates a validation result
 * @param Object
 * @returns Object
 */
export const generateValidation = ({ type = '', message = '' }) => {
  if (!['error', 'success'].includes(type)) {
    // eslint-disable-next-line no-console
    return console.error('type doesn\'t exist')
  }

  if (type === 'error') {
    return {
      success: { status: false },
      error: {
        status: true,
        message
      }
    }
  }

  return {
    success: { status: true },
    error: {
      status: false,
      message
    }
  }
}

/* flatten objects, and deep objects
 * @param obj {Object}
 * @param depth {String|Number}
 * @param parent {String}
 * @param newObj {Object}
 * @returns Object
 */
export const flattenObj = (object, depth = 'deep', parent, newObj = {}) => {
  if (_isEmpty(object)) {
    return {}
  }

  for (const key in JSON.parse(JSON.stringify(object))) {
    if (Object.hasOwnProperty.call(object, key)) {
      const item = object[key]
      const newKey = Object.keys(newObj).includes(key)
        ? `${parent}_${key}`
        : key

      if (typeof item === 'object' && !Array.isArray(item)) {
        if (depth > 0 || depth === 'deep') {
          const tempDepth = depth === 'deep'
            ? depth
            : depth - 1

          flattenObj(item, tempDepth, newKey, newObj)
        } else {
          newObj[newKey] = item
        }
      } else {
        newObj[newKey] = item
      }
    }
  }

  return newObj
}

/**
 * returns top and left positions of elements
 * @param el {HTMLElement}
 * @return obj {Object}
 */
export const getElOffset = el => {
  const rect = el && el.getBoundingClientRect()

  return {
    top: rect?.top + window?.pageYOffset,
    left: rect?.left + window?.scrollX,
    width: el?.offsetWidth,
    height: el?.offsetHeight
  }
}

/**
 * returns url with trailing slash
 * @param value {String}
 * @return value {String}
 */
export const trailingSlash = value => {
  if (!value) {
    return value
  }

  value = value.toString()

  if (value.charAt(value.length - 1) !== '/') {
    value = value + '/'
  }

  return value
}

/**
 * returns true/false if payload is object
 * @param payload
 * @return {Boolean}
 */
export const isObject = payload => !_isArray(payload) && _isObject(payload)

/**
 * returns true/false if payload is array
 * @param payload
 * @return {Boolean}
 */
export const isArray = payload => _isArray(payload) && _isObject(payload)

/**
 * waits until timeout is resolved
 * @param time {Number}
 * example:
 *  await wait(300)
 */
export const wait = time => new Promise(resolve => setTimeout(resolve, time))

/**
 * returns element when its loaded in the window
 * @param elSelector {String}
 * @param all {Boolean}
 * example:
 *  (1) const el = await getElement('.test')
 *  (2) const el = await getElement('.test', true)
 */
export const getElement = async (elSelector, all = false) => {
  const selector = all ? 'querySelectorAll' : 'querySelector'

  while (!document[selector](elSelector)) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise(resolve => requestAnimationFrame(resolve))
  }

  return document[selector](elSelector)
}

export const formatAmount = data => {
  if (!data) {
    return ''
  }

  const number = data.toString()
    .replace(/[,]/g, '')
    .split('.')
  number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return number.join('.')
}

/**
 * returns class without extra spacecs, and false
 * @param str {String}
 * @return {String}
 */
export const removeClassExtras = str => str && str.replace(/false/g, ' ').replace(/\s\s+/g, ' ')

/**
 * checks this.$route is sales funnel
 * @param route {String}
 * @return {Boolean}
 */
export const pageIsFunnel = route => {
  const path = route?.path
  const params = route?.params
  const step = params?.step

  const excludedQuotes = path?.includes('quote') &&
    (
      (path.includes('income-protection/quote') && !path.includes('income-protection/quote/details')) ||
      path.includes('visitors') ||
      path?.includes('results') ||
      path?.includes('resume') ||
      path?.includes('customer') ||
      path?.includes('home-loans/quote/ab-thank-you')
    )

  return !!(
    excludedQuotes ||
    path?.includes('form') ||
    step ||
    (path && path.match(/\/step/gi)) ||
    (path && path.match(/\/details\/$/)) ||
    (path && path.match(/\/health-insurance\/post-sale\/$/)) ||
    (path && path.match(/^\/dashboard(?:\/|$)/)) ||
    (path && path.match(/^\/login(?:\/|$)/)) ||
    (path && path.match(/^\/register(?:\/|$)/)) ||
    (path && path.match(/^\/reset-password(?:\/|$)/)) ||
    (path && path.match(/^\/forgot-password(?:\/|$)/)) ||
    (path && path.match(/^\/authorization(?:\/|$)/)) ||
    (path && path.match(/^\/verify(?:\/|$)/))
  )
}

export const htmlParser = str => {
  if (!str) {
    return str
  }

  return parse(str)
}

/**
 * Set an item in sessionStorage
 * @param key
 */
export const getSession = key => {
  if (import.meta.client) {
    return sessionStorage.getItem(key)
  }

  return null
}

/**
 * Set an item in sessionStorage
 * @param key
 * @param val
 */
export const setSession = (key, val) => {
  if (import.meta.client) {
    return sessionStorage.setItem(key, val)
  }

  return null
}

/*
 * Remove an item from local storage
 * @param key
 */
export const removeSession = key => {
  if (import.meta.client) {
    sessionStorage.removeItem(key)
  }
}

/**
 * handles parsing string to Object/Array
 * @param str {string}
 * @param _default {string} | {Object} | {Array}
 * @param showError {boolean}
 *
 * @return {Object} | {Array}
 * returns parsed string or default value
 */
export const parsify = (str, _default, showError = true) => {
  if ([null, undefined, 'null', 'undefined'].includes(str)) {
    return _default
  }

  const constructorName = str.constructor.name
  if (constructorName === 'Array' || constructorName === 'Object') {
    return JSON.parse(JSON.stringify(str))
  }

  try {
    return JSON.parse(str)
  } catch {
    // eslint-disable-next-line no-console
    showError && console.log(new Error(`Invalid JSON Format. Received ${str}`).stack)
    return _default
  }
}

export const getImageUrl = path => new URL(`${path}`, import.meta.url).href

export const currentHostURL = () => {
  const url = useRequestURL()
  return `${url.protocol}//${url.host}`
}

/*
 * Duration calc by dayjs
 * @param fromDate
 * @param toDate
 * @returns {{months: number, days: number, years: number}}
 */
export const renderDuration = (fromDate, toDate) => {
  const date1 = dayjs(fromDate, 'DD/MM/YYYY')
  const date2 = dayjs(toDate, 'DD/MM/YYYY')

  // get the difference between the moments
  const diff = date2.diff(date1)

  // express as a duration
  const dur = dayjs.duration(diff)
  return {
    years: dur.years(),
    months: dur.months(),
    days: dur.days()
  }
}

/*
 * Prepend currency if not present
 * @param amount
 * @returns {*|string}
 */
export const prependCurrency = amount => {
  if (amount && amount[0] !== '$') {
    return `$${amount}`
  }
  return amount
}
/*
 * Append percentage if not present
 * @param amount
 * @returns {*|string}
 */
export const appendPercentage = amount => {
  if (!amount?.endsWith('%')) {
    return `${amount}%`
  }
  return amount
}

/**
 * Returns list of arrays as {String} with separator
 * @param list {Array}
 * @param separator {String}
 * @return {String}
 */
export const joinArr = (list, separator = ',') => _isEmpty(list) ? '' : list.join(separator)

/**
 * Returns full_name, first_name, and last_name
 * @param str {String}
 * @return {Object}
 */
export const getName = str => {
  if (!str) {
    return ''
  }

  const name = str.split(' ')

  return {
    name: name.join(' '),
    first_name: name?.[0] ?? '',
    last_name: name?.[name.length - 1] ?? ''
  }
}

/**
 * Returns 24 hr time equivalent to 12 hr time given
 * NOTE:
 *  `time` should have AM or PM (i.e: `10:00 AM` `10:00am`)
 *  `format` is either full (default), hr, min, or sec
 *
 * @param time {String}
 * @param format {String}
 * @return {String}
 */
export const to24Hr = (time, format = 'full') => {
  time = time
    .toString()
    .toUpperCase()
    .replace(/ /g, '')

  if (!(time.includes('PM') || time.includes('AM'))) {
    // eslint-disable-next-line no-console
    console.log(`%cto24Hr: ${time} should either have AM or PM`, 'color: red')

    return null
  }

  if (!['full', 'hr', 'min', 'sec', 'hr-min'].includes(format)) {
    // eslint-disable-next-line no-console
    console.log(`%cto24Hr: ${format} should either be 'full', 'hr', 'min', 'sec'`, 'color: red')

    return null
  }

  const temp = time.replace(/PM|AM/, ':00')
    .split(':')

  if (time.includes('AM') && time.includes(12)) {
    temp[0] = '00'
  } else if (time.includes('PM')) {
    temp[0] = +temp[0] >= 1 && +temp[0] <= 11
      ? +temp[0] + 12
      : temp[0]
  }

  if (format === 'hr-min') {
    return `${temp[0]}:${temp[1]}`
  }

  if (format === 'hr') {
    return temp[0]
  }

  if (format === 'min') {
    return temp[1]
  }

  if (format === 'sec') {
    return temp[2]
  }

  return temp.join(':')
}

/*
 * File size rendering
 * @param size
 * @returns {string}
 */
export const fileSize = size => {
  if (size >= 1024) {
    size = size / 1024
    return size >= 1024 ? `${(size / 1024).toFixed(2)} MB` : `${size.toFixed(2)} KB`
  }
  return `${size} B`
}
