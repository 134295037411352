import resolveConfig from 'tailwindcss/resolveConfig'

import _omit from 'underscore/cjs/omit.js'
import _findKey from 'underscore/cjs/findKey.js'

import tailwindConfig from '~/tailwind.config'

const fullConfig = resolveConfig(tailwindConfig)

const width = ref(0)
const height = ref(0)
const outerWidth = ref(0)

const isMobileXs = ref(false)
const isMobile = ref(false)
const isTabletXs = ref(false)
const isTablet = ref(false)
const isDesktop = ref(false)

const currBreakpoint = ref('')

// breakpoint map based on tailwindconfig screens
const screens = fullConfig?.theme?.screens ?? {
  xxxs: '320px',
  xxs: '375px',
  xs: '414px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
  xxl: '2000px'
}

const breakpoints = Object.entries(screens)
  .reduce((curr, value) => {
    return Object.assign(curr, {
      [value[0]]: +value[1].replace('px', '')
    })
  }, {})

function generateDimensions (event) {
  const target = event?.target || event

  width.value = target.innerWidth
  outerWidth.value = target.outerWidth
  height.value = target.innerHeight

  isMobileXs.value = target.innerWidth < breakpoints.xs
  isMobile.value = target.innerWidth < breakpoints.sm

  isTabletXs.value = target.innerWidth >= breakpoints.sm &&
    target.innerWidth < breakpoints.md
  isTablet.value = target.innerWidth >= breakpoints.md &&
    target.innerWidth < breakpoints.lg

  isDesktop.value = target.innerWidth >= breakpoints.lg

  /**
   * get current breapoint value
   */
  const currBpValue = Math.max(...Object.values(_omit(breakpoints, value => !(target.innerWidth >= value))))

  currBreakpoint.value = _findKey(breakpoints, value => value === currBpValue)
}

/**
 * Checks if the `key` passed is within
 * the breakpoint limits based on tailwind breakpoints
 * and if the type is either min of max
 *
 * if (breakpoint('md', 'max'))
 * it will return true if width <= 1023
 *
 * if (breakpoint('md))
 * it will return true if width >= 768
 *
 * if (breakpoint('2xl', 'max))
 * since 2xl is the last item in the breakpoints list
 * type is automatically updated to min,
 * and it will return true if width >= 1536
 *
 * @param key {String}
 * @param type {String} (min is default value)
 * @return {Boolean}
 */
function breakpoint (key, type = 'min') {
  const screen = breakpoints[key]
  const breakpointsArr = Object.keys(breakpoints)

  const bpIndex = breakpointsArr.findIndex(item => item === key)

  const nextBreakpoint = breakpointsArr[bpIndex + 1]
  const nextBreapointValue = breakpoints[nextBreakpoint]
    ? breakpoints?.[nextBreakpoint] - 1
    : 0

  if (!nextBreapointValue) {
    // eslint-disable-next-line no-console
    console.log(
      `%c(deviceChecker.js)\n- ${key} is the last item in the breakpoint.\n- Type is auto updated to %cMIN`,
      'color: orange'
    )
    type = 'min'
  }

  if (type === 'min' && width.value >= screen) {
    return true
  }

  if (type === 'max') {
    if (nextBreapointValue && width.value <= nextBreapointValue) {
      return true
    }
  }

  return false
}

export function deviceChecker () {
  if (import.meta.client) {
    useEventListener(window, 'resize', event => {
      generateDimensions(event)
    })

    generateDimensions(window)
  }

  return {
    /**
     * EXAMPLES:
     * 1. <span v-if="devices.isMobile.value">test</span>
     *
     * 2. <span v-if="devices.width.value > 10">test</span>
     *
     * 3. <span v-if="devices.height.value > 10">test</span>
     *
     * 4. <span v-if="devices.currBreakpoint === 'lg'">
     *      test
     *    </span>
     *    only shows when the actual breakpoint is 'lg'
     *    otherwise its hidden
     *
     * 5. <span v-if="devices.breakpoint('lg')">test</span>
     *    <span v-if="devices.breakpoint('lg', 'max')">test</span>
     */
    devices: {
      isMobileXs,
      isMobile,
      isTablet,
      isTabletXs,
      isDesktop,
      outerWidth,
      width,
      height,
      currBreakpoint,
      breakpoint
    }
  }
}
