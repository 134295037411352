/*
 * Consider object key as vertical slug
 * @type {{ENERGY: string, PERSONAL_LOANS: string, CAR_INSURANCE: string, HEALTH_INSURANCE: string, LIFE_INSURANCE: string, CREDIT_CARDS: string, CAR_LOANS: string, HOME_LOANS: string, DEFAULT: string}}
 */
export const VERTICAL = {
  HEALTH_INSURANCE: 'Health Insurance',
  LIFE_INSURANCE: 'Life Insurance',
  ENERGY: 'Energy',
  PERSONAL_LOANS: 'Personal Loans',
  CREDIT_CARDS: 'Credit Cards',
  HOME_LOANS: 'Home Loans',
  CAR_LOANS: 'Car Loans',
  CAR_INSURANCE: 'Car Insurance',
  DEFAULT: 'Default'
}

export const VERTICAL_LIST = [VERTICAL.HEALTH_INSURANCE, VERTICAL.LIFE_INSURANCE, VERTICAL.ENERGY, VERTICAL.PERSONAL_LOANS, VERTICAL.CREDIT_CARDS, VERTICAL.HOME_LOANS, VERTICAL.CAR_LOANS, VERTICAL.CAR_INSURANCE]
