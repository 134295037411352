<template>
  <component
    :is="!to ? 'button' : 'a'"
    :href="hrefUrl"
    class="font-bold text-center block h-full"
    :class="[
      styles.themes[theme],
      styles.sizes[size],
      styles.round[rounded],
      {
        'w-full': full,
        'w-max': !full,
        'inline-flex items-center justify-center': icon,
        'mx-auto': align === 'center',
        'shadow-md': shadow,
        'lg:w-max': widthFullContentDesktop
      }
    ]"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot />

    <IconsChevron
      v-if="icon"
      direction="right"
      type="rounded"
      :fill="iconFill"
      class="ml-3"
      width="15"
      height="9"
    />
  </component>
</template>

<script setup>
import { PARTNERS_LIST } from '@/helpers/partners.constants'

const utmsStore = useUtmsStore()

defineOptions({
  name: 'AtomsCta'
})

const emit = defineEmits(['click'])

const props = defineProps({
  to: {
    type: String,
    default: null
  },

  theme: {
    type: String,
    default: 'primary',
    validator: value => ['primary', 'secondary', 'tertiary', 'tertiary2', 'light', 'primaryWhite', 'destructive', 'plain'].includes(value)
  },

  size: {
    type: String,
    default: 'large',
    validator: value => ['extra small', 'micro', 'mini', 'small', 'medium', 'large'].includes(value)
  },

  disabled: {
    type: Boolean,
    default: false
  },

  full: {
    type: Boolean,
    default: true
  },

  icon: {
    type: Boolean,
    default: false
  },

  iconFill: {
    type: String,
    default: '#193560'
  },

  shadow: {
    type: Boolean,
    default: false
  },

  align: {
    type: String,
    default: 'left'
  },

  hasUtms: {
    type: Boolean,
    default: false
  },

  rounded: {
    type: String,
    default: 'full',
    validator: value => ['standard', 'full'].includes(value)
  },

  widthFullContentDesktop: {
    type: Boolean,
    default: false
  }
})

// variables
const styles = computed(() => {
  return {
    themes: {
      primary:
            'bg-primary rounded-full hover:text-white hover:bg-dark hover:shadow-md active:bg-primary active:shadow-inner active:text-dark disabled:bg-gray-400 disabled:cursor',
      secondary:
            'bg-transparent border-dark border text-dark hover:border-primary hover:bg-primary hover:text-white hover:shadow-md focus:shadow-inner disabled:opacity-40 disabled:bg-transparent disabled:border-dark disabled:shadow-none disabled:text-dark',
      tertiary:
            'bg-transparent border-0 text-dark underline hover:text-primary disabled:text-gray-500',
      tertiary2:
            'bg-white border-0 text-dark hover:bg-yellow-600 hover:text-white disabled:text-gray-500',
      light:
            'bg-transparent border border-white text-white hover:bg-white hover:text-dark focus:bg-white focus:text-dark focus:shadow-inner disabled:opacity-40 disabled:text-gray-500 disabled:bg-gray-300',
      primaryWhite:
            'bg-primary hover:bg-dark border border-primary hover:border-dark text-white shadow-md hover:text-white focus:shadow-inner disabled:bg-gray-300 disabled:shadow-none disabled:text-zinc-400 disabled:border-zinc-400 ',
      destructive:
            'bg-pink-600 border border-pink-600 text-white focus:shadow-inner disabled:opacity-40',
      plain: ''
    },
    sizes: {
      'extra small': 'text-[0.75rem] py-[0.125rem] px-6',
      micro: 'text-[0.875rem] py-[0.3125rem] px-2 xl:px-5',
      mini: 'text-[0.875rem] py-[0.3125rem] px-4 md:px-6',
      small: 'text-[0.875rem] py-[0.3125rem] px-8',
      medium: 'text-[1rem] py-2',
      large: 'text-[1.125rem] leading-[1.625rem] py-[0.6875rem] px-8'
    },
    round: {
      standard: 'rounded',
      full: 'rounded-full'
    }
  }
})

const hrefUrl = computed(() => {
  let url = props.hasUtms
    ? `${props.to}?${utmsStore.GET_UTMS_AS_QUERY}`
    : props.to

  PARTNERS_LIST.forEach(partner => {
    if (partner.link === props.to) {
      const temp = partner.hasCustomUtms
        ? utmsStore.GET_UTMS_PARTNER(partner.name)
        : utmsStore.GET_UTMS_AS_QUERY

      url = `${url}?${temp}`
    }
  })

  return url
})

// functions
const handleClick = event => emit('click', event)
</script>
