/*
| ======================================
| This File is for tracking / gtm helper
| ======================================
*/

import { DEFAULT_ENABLE_HOTJAR_LIST } from '~/constants/hotjar.constants'

export default defineNuxtPlugin({
  name: 'tracking',
  parallel: true,
  setup () {
    const userStore = useUserStore()
    class Tracking {
      constructor () {
        this.additionalPagePayload = {}
      }

      updatePayload () {
        const pathName = window.location.pathname
        const ogType = document.querySelector('meta[property="og:type"]')

        this.dataLayer = window.dataLayer || []
        this.page = {
          fullUrl: window.location.href,
          fullPath: `${pathName}${window.location.search}`,
          path: pathName,
          title: document.title,
          pageType: ogType ? ogType.content : '',
          hotjar: false
        }

        // ENABLE HOTJAR on specific pages
        if (DEFAULT_ENABLE_HOTJAR_LIST.includes(pathName)) {
          this.page.hotjar = true
        }

        // Add the user's email hash if available
        if (userStore.isUserLoggedIn) {
          this.additionalParams = {
            ...this.additionalParams,
            clubplus_user: { emailHashed: userStore.getUserProfile.hash_id }
          }
        }
      }

      pageview (payload = {}) {
        this.event = 'pageview'
        this.additionalParams = {}
        this.additionalPagePayload = payload
        this.execute()
      }

      click (eventElement, eventTargetUrl = '', params) {
        const eventElementPath = eventElement.path ? eventElement.path : [eventElement.target]
        const findButtonHref = eventElementPath.filter(d => {
          return (d.tagName === 'BUTTON' || d.tagName === 'A' || d.tagName === 'a') && d.getAttribute('id')
        })

        let elementUrl = eventTargetUrl ?? (findButtonHref.length < 1 ? '' : findButtonHref[0].getAttribute('href') ? findButtonHref[0].getAttribute('href') : eventTargetUrl || window.location.href)
        if (!elementUrl.includes('http')) {
          elementUrl = window.location.origin + elementUrl
        }

        this.additionalParams = {
          elementId: findButtonHref.length > 0 ? findButtonHref[0].id : '',
          elementInnerText: findButtonHref.length < 1 ? '' : findButtonHref[0].innerText !== '' ? findButtonHref[0].innerText : findButtonHref[0].getAttribute('data-innertext'),
          elementButtonUrl: elementUrl,
          ...params
        }

        this.event = 'Button Clicked'
        this.execute()
      }

      login (params) {
        this.event = 'My Account Log In'
        this.additionalParams = params
        this.execute()
      }

      gsdCallback (params) {
        this.event = 'Callback Requested'
        this.additionalParams = params
        this.execute()
      }

      personalDetails (vertical, params) {
        this.event = vertical + ' Quote - Personal Details Added'
        this.additionalParams = params
        this.execute()
      }

      customEvent (event, params) {
        this.event = event
        this.additionalParams = params
        this.execute()
      }

      push () {
        this.updatePayload()
        this.dataLayer.push({
          event: this.event,
          page: {
            ...this.page,
            ...this.additionalPagePayload
          },
          ...this.additionalParams
        })
      }

      execute () {
        if (this.event === 'pageview') {
          // There is a known bug around page title. delaying the push by .5 second
          // https://github.com/nuxt/vue-meta/issues/567
          setTimeout(this.push.bind(this), 500)
          return
        }
        this.push()
      }
    }

    return {
      provide: {
        tracking: new Tracking()
      }
    }
  }
})
