// plugins/sentry.client.ts
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const { sentryDSN, sentryEnvironment } = useEnv()
  if (!sentryDSN || sentryEnvironment === 'local') {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentryDSN,
    environment: sentryEnvironment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', 'https://your-server.com'],
    // Adjust sampling rates based on your needs
    tracesSampleRate: 0.1, // Capture 10% of transactions
    replaysSessionSampleRate: 0.05, // Capture 5% of user sessions
    replaysOnErrorSampleRate: 0.2, // Capture replays for 20% of errors
    // Optionally configure ignore and level here
    ignore: [
      // Array of event types or functions to ignore (e.g., ['console', 'xhr'] )
    ],
    level: 'error', // Only capture errors (adjust as needed)
    beforeSend (event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        )
      }
      // Continue sending to Sentry
      return event
    }
  })

  /* vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }))
  Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  } */
})
