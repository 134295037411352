export const useLayoutsStore = defineStore('layouts', {
  state: () => ({
    scrollPosition: 0,

    vertical: '',
    guideTemplate: '',

    navigation: {
      showMegaNav: false,
      layout: 'default',
      ctaOnly: false,
      ctaText: 'GET FREE QUOTES',
      hasShadow: false,
      hasCallCta: true,
      hasLoginPopup: false
    },

    footer: {
      show: true
    },
    pageLoader: [],

    layoutBg: 'bg-slate-50'
  }),

  actions: {
    UPDATE_GUIDE_TEMPLATE (template) {
      this.$state.navigation.ctaOnly = ['ArticleLight', 'ArticleLightWithSidebar'].includes(template)
    },
    /*
     * Mutation for page loader
     * @param payload
     */
    setPageLoader (payload) {
      if (payload) {
        this.pageLoader.push(payload)
      } else {
        this.pageLoader.splice(0, 1)
      }
    },

    resetPageLoader () {
      this.pageLoader.splice('*')
    },

    /*
     * Mutator for layout bg class
     * @param bgClass
     */
    setLayoutBg (bgClass) {
      this.layoutBg = bgClass
    },

    RESET_LAYOUT_BG () {
      this.layoutBg = 'bg-slate-50'
    }
  },

  getters: {
    /*
     * Accessor for pageLoader
     * @param state
     * @returns {boolean}
     */
    getPageLoaderStatus (state) {
      return !!state.pageLoader.length
    },
    /*
     * Accessor for layout bg class
     * @param state
     * @returns {string|*}
     */
    getLayoutBg (state) {
      return state.layoutBg
    }
  }
})
