import { ApiService } from './api.service'

class QuoteService {
  constructor () {
    this.request = new ApiService()
  }

  /*
   * Quotes Fetch API service
   * @returns {*}
   */
  fetchLeads = params => {
    return this.request.get('/leads', { params })
  }
  /*
   * Quotes Opportunities Fetch API service
   * @param params
   * @returns {AsyncData<PickFrom<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>, KeysOf<FetchResult<NitroFetchRequest, "get" extends AvailableRouterMethod<NitroFetchRequest> ? "get" : AvailableRouterMethod<NitroFetchRequest>>>> | DefaultAsyncDataValue, DefaultAsyncDataErrorValue | FetchError>}
   */
  opportunities = params => {
    return this.request.get('/opportunities', { params })
  }
}

export default QuoteService
